<template>
  <v-container fluid>
    <v-row>
      <v-col class="col-12">
        <h1>Purchase orders awaiting authorisation</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="col-12">
        <v-simple-table fixed-header height="400">
          <thead>
            <tr>
              <th>Supplier</th>
              <th>Date raised</th>
              <th>Date authed</th>
              <th>Date ordered</th>
              <th>Ordered by</th>
              <th>Delivery date</th>
              <th>Value</th>
              <th>&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(po, index) in purchaseOrders" :key="`s_${index}`">
              <td>{{ po.supplier.name }}</td>
              <td>{{ po.date_of_requisition | tinyDate }}</td>
              <td>{{ po.date_of_authorisation | tinyDate }}</td>
              <td>{{ po.date_of_order | tinyDate }}</td>
              <td><span v-if="po.orderer">{{ po.orderer.name }}</span></td>
              <td>{{ po.delivery_date | tinyDate }}</td>
              <td>{{ po.order_value | currency }}</td>
              <td>
                <router-link custom v-slot="{ navigate }" :to="`/purchasing/purchase-orders-view/${po.id}`">
                  <v-btn
                    x-small
                    @click="navigate"
                    @keypress.enter="navigate"
                    role="link">
                    View
                  </v-btn>
                </router-link>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
        <v-pagination
          class="pt-4"
          v-model="page"
          :length="length"
          prev-icon="mdi-menu-left"
          next-icon="mdi-menu-right"
          :total-visible="totalVisible"
        ></v-pagination>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from '../../axios';

export default {
  name: 'PurchasingAwaitingAuthorisation',
  computed: {
    token() {
      return this.$store.state.token;
    },
  },
  data() {
    return {
      purchaseOrders: [],
      page: 1,
      length: 0,
      modal: false,
      totalVisible: 9,
    };
  },
  watch: {
    page() {
      this.getPurchaseOrders();
    },
  },
  methods: {
    getPurchaseOrders() {
      const postData = {};
      postData.search = this.search;
      axios.get(`/purchaseOrders/getAwaitingAuthorisation/${this.page}.json?token=${this.token}`)
        .then((response) => {
          this.purchaseOrders = response.data.purchaseOrders;
          this.length = response.data.length;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    this.getPurchaseOrders();
  },
};
</script>
